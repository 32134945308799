function mobileNav() {
  // Check for click events on the navbar burger icon
  $(".navbar-burger").click(function() {

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $(".navbar-burger").toggleClass("is-active");
      $(".navbar-menu").toggleClass("is-active");
      return false;
  });
}

function servicesDropdown() {
  $(".navbar-link[data-dropdown='services-link']").click(function() {

      $(".navbar-item[data-dropdown='services']").toggleClass("is-active");
      return false;
  });
}

function smoothScroll(trig, dest) {
  $(trig).click(function(e){
      e.preventDefault();
      $("html, body").animate({ scrollTop: $(dest).offset().top }, 1000);
      return false;
  });
}

function closeNotifications(){
  $(".notification > button.delete").click(function() {
    $(this).parent().addClass('is-hidden');
    return false;
  });
}

function slideDownReveal(trig, dest, duration){
  duration = duration || 700;
  $(trig).click(function() {
    $(this).children().toggleClass('is-flipped');
    $(dest).slideToggle(duration);
    console.log(duration);
  });
}


// function popcolor(hcCard) {
//   var wScroll = $(window).scrollTop();
//   var card = $(hcCard);
//   if (card.length) {
//     var cardTop = card.offset().top;
//   }
//
//
//   if($(window).width() < 640) {
//     if(wScroll > cardTop - $(window).height()*0.6 && wScroll < cardTop-50) {
//       card.addClass("in-view");
//     } else {
//       card.removeClass("in-view");
//     }
//   }
// }

// $(window).scroll(function () {
//   popcolor('#hc-camp');
//   popcolor('#hc-karate');
// });


$(function() {
  mobileNav();
  smoothScroll('#hero-learn', '#home-services');
  servicesDropdown();
  closeNotifications();
  // slideDownReveal(".show-more-btn", ".collapsed-content", 300);
});
